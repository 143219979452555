import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useMutation, useQuery } from '@apollo/client';

import { IContactInput, ILeadResponse } from '../../../../__types__/global';
import { OFFICE_QUERY } from '../../../../../client/__graphql__/queries';
import { CONTACT_MUTATION } from '../../../../../client/__graphql__/mutations';
import { createGTMEvent } from '../../../../utils/tracking/gtm';

import { useFormTransition } from '../../../../utils/hooks/useFormTransition';
import { useForm } from '../../../../utils/hooks/useForm';

import { Section } from '../../../layout/Section';
import { Container } from '../../../layout/Container';
import { Input } from '../../../ui/form/Input';
import { Textarea } from '../../../ui/form/Textarea';
import { FormError } from '../../../ui/form/FormError';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Form, FormRow } from '../../../ui/form/Form';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { Button } from '../../../ui/button/Button';
import { ButtonLink } from '../../../ui/link/ButtonLink';

import { AccordionGeneral } from '../../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../../partials/AccordionBenefits';

import { Thanks } from '../../../layout/Thanks';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { SiteContext } from '../../../../utils/context/SiteContext';
import { Seo } from '../../../../utils/seo/Index';
import { useDigtectiveContext } from '../../../../__lib__/digtective/context/hooks/useDigtectiveContext';

interface IRoute {
  urltag: string;
}

const Contact: React.FC<RouteComponentProps<IRoute>> = ({ match }) => {
  const { routeConfig }: any = React.useContext(SiteContext);
  const { submitWithDigger } = useDigtectiveContext();

  const { data, loading: loadingOffice }: any = useQuery(OFFICE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        urltag: match?.params?.urltag
      }
    }
  });

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: !loadingOffice && data
  });

  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const [lead, { error: postError }] = useMutation<
    ILeadResponse,
    IContactInput
  >(CONTACT_MUTATION, {
    onError: ({ graphQLErrors, networkError }) => {
      console.log(graphQLErrors, networkError);
    },
    onCompleted: ({ lead: { contact } }) => {
      setLoading(false);
      if (contact && contact.success) {
        window.scroll(0, 0);
        setPosted(true);
        createGTMEvent({
          event: 'pmFormSubmission',
          gaEvent: 'Kontaktskjema - megler',
          gaCategory: 'RT_KONTAKT',
          gaAction: 'RT_SEND_KONTAKTSKJEMA',
          gaLabel: 'RT_KONTAKT_KONTOR'
        });
      }
    }
  });

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Ola Nordmann',
        value: '',
        label: 'Navn'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: '1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: '00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        placeholder: 'Melding til megler',
        value: '',
        label: 'Beskjed *',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      setLoading(true);
      submitWithDigger(
        {
          zip: preparedFields.zip
        },
        ({ diggerId }) => {
          lead({
            variables: {
              input: {
                leadType: 'KONTAKT',
                name: preparedFields.name,
                address: preparedFields.address,
                zip: preparedFields.zip,
                email: preparedFields.email,
                phone: preparedFields.phone,
                comment: preparedFields.comment,
                identifier: match.params.urltag,
                diggerId,
                pageType: 'KONTOR',
                referer: `${location.pathname}${location.search}`
              }
            }
          });
        }
      );
    }
  });

  return (
    <>
      <Seo
        title={`${data?.office?.name} - PrivatMegleren - Nordeas Eiendomsmeglerkjede`}
        description="Søk blant våre eiendommer til salgs"
        image=""
        url=""
      />
      <Container
        style={{ paddingTop: '100px', paddingBottom: '50px', ...pageFadeIn }}
      >
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              Kontakt
            </Heading>
            <Paragraph center={true} className="form-text">
              Som kunde hos PrivatMegleren får du en skreddersydd prosess
              tilpasset dine behov og ønsker.
            </Paragraph>
            {!loadingOffice && data && data.office && data.office.email ? (
              <Paragraph center={true}>
                <a href={`mailto:${data.office.email}`}>{data.office.email}</a>
              </Paragraph>
            ) : null}
          </Section>
          <Form onSubmit={handleSubmit} noValidate>
            {postError &&
              postError.graphQLErrors.map((error) => (
                <FormError message={error.message} />
              ))}
            {fields
              .filter((item: any) => item.type !== 'textarea')
              .map((item: any, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item.type}
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
            {fields
              .filter((item: any) => item.type === 'textarea')
              .map((item: any, index) => {
                return (
                  <FormRow key={`field_${index}2`}>
                    <Textarea
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Send
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg innen kort tid for å
              avtale en befaring.
            </Paragraph>
            <Paragraph center={true}>
              I mellomtiden kan du lese mer om salg av eiendom her.
            </Paragraph>
            <AccordionGeneral />
          </Section>
          <Section className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.25}
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/falk_bakside2.jpg"
      />
    </>
  );
};

export default Contact;

const FormWrapper = styled(animated.div)``;
